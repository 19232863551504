@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.contactSection {
  margin-top: 100px;
  margin-bottom: 100px;
  .contactSectionWrapper {
    position: relative;
    background-image: url("../../../assets/images/contact-section-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 220px 20px 154px;
    @include xl {
      padding: 120px 20px 54px;
    }
    @include md {
      padding: 40px 20px;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }
    .contactForm {
      position: relative;
      z-index: 2;
      max-width: 1200px;
      margin: 0 auto;
      .formControl {
        margin-bottom: 40px;
        label {
          display: block;
          font-family: Inter;
          font-size: 28px;
          font-weight: 400;
          color: $white;
          margin-bottom: 20px;
          @include xl {
            font-size: 24px;
          }
          @include md {
            font-size: 20px;
          }
          @include sm {
            font-size: 16px;
          }
        }
        input,
        textarea {
          width: 100%;
          padding: 21px 30px;
          border: 1px solid $white;
          background-color: transparent;
          color: $white;
          font-family: Inter;
          font-size: 24px;
          font-weight: 300;
          &::placeholder {
            font-style: italic;
            color: #ffffff50;
          }
          @include md {
            padding: 15px 25px;
            font-size: 20px;
          }
          @include md {
            padding: 10px 20px;
            font-size: 16px;
          }
        }
        textarea {
          min-height: 400px;
          @include xl {
            min-height: 300px;
          }
          @include md {
            min-height: 200px;
          }
          @include sm {
            min-height: 150px;
          }
        }
      }
      .sendBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          display: block;
          font-family: Inter;
          font-size: 28px;
          font-weight: 400;
          letter-spacing: 1em;
          color: $white;
          padding: 0px 4px 16px 20px;
          border-bottom: 1px solid $white;
          background-color: transparent;
          text-transform: uppercase;
          text-align: right;
          transition: 0.3s;
          &:hover {
            transform: scale(1.1);
          }
          // &::after {
          //   content: " ";
          //   margin-left: -1em;
          // }
          @include xl {
            font-size: 24px;
          }
          @include md {
            font-size: 20px;
          }
          @include sm {
            font-size: 16px;
          }
        }
      }
    }
  }
  @include md {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
