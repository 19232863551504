@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.newsSection {
  margin-top: 100px;
  margin-bottom: 100px;
  .newsSectionWrapper {
    position: relative;
    background-image: url("../../../assets/images/news-section-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 125px 20px 400px;
    .newsFullWidth {
      display: flex;
      margin-bottom: 50px;
      &.left {
        justify-content: flex-start;
      }
      &.right {
        justify-content: flex-end;
      }
      width: 100%;
      .news {
        width: 48%;
        .newsTitle {
          font-family: Inter;
          font-size: 30px;
          font-weight: 700;
          color: $white;
          @include xxl {
            font-size: 26px;
          }
          @include lg {
            font-size: 20px;
          }
          @include sm {
            font-size: 16px;
            text-align: center;
          }
        }
        .newsDate {
          margin-bottom: 30px;
          margin-top: 30px;
          font-family: Inter;
          font-size: 30px;
          font-weight: 400;
          color: $white;
          > span:nth-of-type(1) {
            margin-right: 30px;
          }
          @include xxl {
            font-size: 26px;
          }
          @include lg {
            font-size: 20px;
            margin-top: 20px;
          }
          @include sm {
            font-size: 16px;
          }
        }
        .newsAddress {
          font-family: Inter;
          font-size: 30px;
          font-weight: 400;
          color: $white;
          @include xxl {
            font-size: 26px;
          }
          @include lg {
            font-size: 20px;
          }
          @include sm {
            font-size: 16px;
          }
        }
        .newsDetails {
          position: relative;
          margin-top: 36px;
          @include lg {
            margin-top: 20px;
          }
          .details {
            position: relative;
            display: flex;
            align-items: center;
            .showDetails {
              width: 100%;
              display: flex;
              position: absolute;
              left: 0;
              top: 50px;
              z-index: 999;
              background-color: #0000004d;
              pre {
                white-space: pre-line;
                font-family: Inter;
                font-size: 24px;
                font-weight: 400;
                color: $white;
                @include xxl {
                  font-size: 20px;
                }
                @include lg {
                  font-size: 16px;
                }
              }
              padding: 50px;
              visibility: hidden;
              opacity: 0;
              transition: visibility 0.3s linear, opacity 0.3s linear;
              @include xxl {
                font-size: 20px;
              }
              @include lg {
                font-size: 16px;
                padding: 10px;
                top: 35px;
              }

              @include sm {
                top: 25px;
              }
            }
            &:hover {
              .showDetails {
                visibility: visible;
                opacity: 1;
              }
              .desc {
                > svg {
                  transform: rotate(0deg);
                }
              }
            }
            .desc {
              display: flex;
              align-items: center;
              cursor: pointer;
              span {
                font-family: Inter;
                font-size: 36px;
                font-weight: 400;
                color: $white;
                text-decoration: underline;
                margin-right: 16px;
                @include xxl {
                  font-size: 26px;
                }
                @include lg {
                  font-size: 20px;
                }
                @include sm {
                  font-size: 16px;
                }
              }
              > svg {
                transform: rotate(180deg);
              }
              @include sm {
                > svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
            .instargram {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              border: 1px solid $white;
              margin-left: 50px;
              cursor: pointer;
              @include lg {
                margin-left: 20px;
              }
              @include sm {
                width: 20px;
                height: 20px;
                svg {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }
        @include md {
          width: 100%;
        }
      }
    }
    @include lg {
      padding: 60px 20px 300px;
    }
  }
  @include lg {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
