@import "_fonts.scss";
@import "_variables.scss";
@import "_mixins.scss";

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:focus {
  outline: none !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  > .app-content {
    height: 100%;
  }
}

.swiper {
  max-height: 980px;
  width: 100%;
  height: 100%;
  @include md {
    max-height: 480px;
  }
  @include sm {
    max-height: 320px;
  }
  .swiper-pagination-bullets {
    display: flex !important;
    flex-direction: column !important;
    left: unset !important;
    width: auto !important;
    right: 40px !important;
    top: unset !important;
    bottom: 40px !important;
    .swiper-pagination-bullet {
      width: 10px !important;
      height: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      background: $primary-color;
      border: 1px solid $primary-color;
      padding: 3px;
      @include lg {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }
    }
    .swiper-pagination-bullet-active {
      outline: 1px solid $primary-color !important;
      border: 1px solid $black;
    }
    @include md {
      right: 20px !important;
      bottom: 20px !important;
    }
  }
}

.hero-swiper {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: unset !important;
    bottom: 40px !important;
    border: 1px solid $primary-color;
    width: 30px !important;
    height: 30px !important;
    color: $primary-color !important;
    &::after {
      font-size: 20px !important;
      @include md {
        font-size: 14px !important;
      }
    }
    @include md {
      bottom: 10px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
  .swiper-button-prev {
    left: 40px !important;
    @include md {
      left: 10px !important;
    }
  }
  .swiper-button-next {
    right: unset !important;
    left: 90px !important;
    @include md {
      left: 40px !important;
    }
  }
}

.project-details-swiper-main {
  height: 80%;
  width: 100%;
  .swiper-slide {
    background-color: #232020;
    height: 800px;
    img {
      display: block;
      width: 80%;
      height: 100%;
      object-fit: contain;
      margin: auto;
      @include md {
        width: 100%;
      }
    }
    @include xxl {
      height: 600px;
    }
    @include md {
      height: 400px;
    }
    @include sm {
      height: 300px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 50% !important;
    transform: translateY(50%);
    border: 1px solid $primary-color;
    width: 30px !important;
    height: 30px !important;
    color: $primary-color !important;
    &::after {
      font-size: 20px !important;
      @include md {
        font-size: 14px !important;
      }
    }
    @include md {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .swiper-button-prev {
    left: 100px !important;
    @include xxl {
      left: 60px !important;
    }
    @include lg {
      left: 30px !important;
    }
    @include md {
      left: 10px !important;
    }
  }
  .swiper-button-next {
    right: 100px !important;
    @include xxl {
      right: 60px !important;
    }
    @include lg {
      right: 30px !important;
    }
    @include md {
      right: 10px !important;
    }
  }
}

.project-details-swiper-secondary {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
  .swiper-slide {
    height: 300px;
    width: 25%;
    opacity: 0.4;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
    }
    @include xxl {
      height: 200px;
    }
    @include lg {
      height: 150px;
    }
    @include sm {
      height: 70px;
    }
  }
  .swiper-slide-thumb-active {
    opacity: 1;
    border: 2px solid $white;
  }
}

input,
textarea,
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  resize: none;
}

a {
  display: inline-block;
  text-decoration: none;
}

button {
  cursor: pointer;
}

input,
button,
select {
  display: inline-block;
  padding: 0;
  border: 0;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

body {
  margin: 0;
  padding: 0;
  background-color: #232020;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.container {
  max-width: 1620px;
  margin: 0px auto;
}
