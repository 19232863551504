// Colors
$white: #ffffff;
$black: #000000;
$primary-color: #ff0000;

$screen-sm-max: 576px;
$screen-md-max: 768px;
$screen-lg-max: 992px;
$screen-xl-max: 1230px;
$screen-xxl-max: 1440px;
