@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.heroSection {
  height: 100%;
  max-height: 980px;
  @include md {
    max-height: 480px;
  }
  @include sm {
    max-height: 320px;
  }
}
