@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.projectTitle {
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  color: $primary-color;
  margin-bottom: 100px;
  @include xl {
    font-size: 36px;
    margin-bottom: 40px;
  }
  @include md {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.projectDescription {
  pre {
    white-space: pre-line;
    font-family: Inter;
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    color: $white;
    max-width: 1230px;
    margin: 100px auto;
    @include xl {
      font-size: 32px;
      line-height: 44px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include md {
      font-size: 24px;
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @include sm {
      font-size: 16px;
    }
  }
}

.projectsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 0 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include md {
    grid-template-columns: 1fr;
  }
}
.projectsSection {
  margin-top: 100px;
  margin-bottom: 100px;
  .viewAll {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    button {
      font-family: Inter;
      font-size: 40px;
      font-weight: 500;
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
      background-color: transparent;
      padding: 20px;
      letter-spacing: 5px;
      transition: 0.3s;
      &:hover {
        transform: scale(1.1);
      }
      @include xl {
        font-size: 24px;
      }
      @include md {
        font-size: 20px;
      }
      @include sm {
        font-size: 16px;
        padding: 10px;
      }
    }
    @include md {
      margin-top: 20px;
    }
  }
  @include md {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
