@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.goTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: $primary-color;
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 9999;
  cursor: pointer;
}
