@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.footer {
  padding: 70px 20px 30px;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .appLogo {
    max-width: 552px;
    cursor: pointer;
    margin-bottom: 47px;
  }
  ul.navigation {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      font-family: Inter;
      font-size: 24px;
      font-style: italic;
      font-weight: 300;
      color: $primary-color;
      &:not(:last-of-type) {
        margin-right: 60px;
        @include xxl {
          margin-right: 40px;
        }
        @include md {
          margin-right: 0;
        }
      }
      @include md {
        text-align: center;
        padding: 10px 0px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    @include md {
      display: grid;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .social {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
    > div {
      cursor: pointer;
      border: 1px solid $white;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .divider {
    background-color: $white;
    height: 1px;
    max-width: 800px;
    width: 100%;
    margin: 47px auto 22px;
  }
  .copyright {
    display: flex;
    align-items: center;
    .copyrightText {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: $white;
      margin-left: 10px;
    }
  }
  @include md {
    padding: 30px 20px 30px;
  }
}
