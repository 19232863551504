@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.projectCard {
  position: relative;
  cursor: pointer;
  &:hover {
    .projectFooter {
      font-weight: 300;
    }
  }
  .projectImage {
    position: relative;
    height: 850px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    > p {
      display: flex;
      align-items: center;
      position: absolute;
      left: 10px;
      bottom: 10px;
      font-family: Inter;
      font-size: 12px;
      font-style: italic;
      font-weight: 100;
      color: $white;
      margin: 0;
      svg {
        margin-right: 10px;
      }
      span {
        font-weight: 700;
        color: $primary-color;
        margin-left: 5px;
      }
    }
    @include xxl {
      height: 600px;
    }
    @include lg {
      height: 400px;
    }
    @include sm {
      height: 300px;
    }
  }
  .projectFooter {
    border-left: 1px solid $primary-color;
    border-right: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    font-family: Inter;
    font-size: 40px;
    font-style: italic;
    font-weight: 100;
    text-align: right;
    color: $primary-color;
    padding: 30px 60px;
    transition: 0.3s;
    @include xl {
      font-size: 32px;
      padding: 20px 40px;
    }
    @include sm {
      font-size: 24px;
      padding: 10px 20px;
    }
  }
}
