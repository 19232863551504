@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.bioSection {
  margin-top: 100px;
  margin-bottom: 100px;
  .bioSectionWrapper {
    padding: 50px;
    .bioImageAndProfessions {
      margin-bottom: 150px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .bioImage {
        position: relative;
        border: 1px solid $primary-color;
        height: 1300px;
        > img {
          position: absolute;
          top: -50px;
          left: -50px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include md {
            top: -20px;
            left: -20px;
          }
        }
        p {
          position: absolute;
          right: 50px;
          bottom: -70px;
          font-family: Inter;
          font-size: 24px;
          font-style: italic;
          font-weight: 300;
          color: $white;
          span {
            font-weight: 500;
            color: $primary-color;
            margin-right: 5px;
          }
          @include md {
            font-size: 16px;
            bottom: -50px;
          }
        }
        @include xxl {
          height: 800px;
        }
        @include xl {
          height: 600px;
        }
        @include lg {
          height: 400px;
        }
      }
      .fullnameAndProfession {
        padding: 0px 74px;
        .fullname {
          font-family: Inter;
          font-size: 80px;
          font-style: italic;
          font-weight: 100;
          text-align: center;
          color: $white;
          @include xxl {
            font-size: 48px;
          }
          @include xl {
            font-size: 40px;
          }
          @include lg {
            font-size: 30px;
          }
          @include md {
            font-size: 24px;
          }
          @include sm {
            font-size: 18px;
          }
        }
        .professions {
          > p {
            margin-top: 200px;
            span {
              position: relative;
              font-family: Inter;
              font-size: 60px;
              font-style: italic;
              font-weight: 300;
              color: $white;
              &::after {
                content: "";
                position: absolute;
                height: 1px;
                background-color: $primary-color;
                width: 50%;
                right: 0;
                bottom: -10px;
                @include sm {
                  bottom: -5px;
                }
              }
              @include xxl {
                font-size: 48px;
              }
              @include xl {
                height: 40px;
              }
              @include lg {
                font-size: 30px;
              }
              @include md {
                font-size: 24px;
              }
              @include sm {
                font-size: 16px;
              }
            }
            &:nth-of-type(1) {
              text-align: left;
            }
            &:nth-of-type(2) {
              text-align: center;
              @include sm {
                span {
                  margin-left: 45px;
                }
              }
            }
            &:nth-of-type(3) {
              text-align: right;
            }
            @include xxl {
              margin-top: 150px;
            }
            @include xl {
              margin-top: 100px;
            }
            @include lg {
              margin-top: 70px;
            }
            @include md {
              margin-top: 50px;
            }
            @include sm {
              margin-top: 20px;
            }
          }
        }
        @include xxl {
          padding: 0 40px;
        }
        @include lg {
          padding: 0 20px;
        }
        @include sm {
          grid-row: 1;
          margin-bottom: 30px;
          padding: 0;
        }
      }
      @include sm {
        grid-template-columns: 1fr;
        margin-bottom: 80px;
      }
    }
    .bioDescription {
      pre {
        font-family: Inter;
        font-size: 40px;
        font-weight: 400;
        line-height: 60px;
        text-align: left;
        color: $white;
        width: 100%;
        white-space: pre-line;
        @include xl {
          font-size: 32px;
          line-height: 44px;
        }
        @include md {
          font-size: 24px;
          line-height: 30px;
        }
        @include sm {
          font-size: 16px;
        }
      }
      max-width: 1230px;
      margin: 0 auto;
    }
    @include md {
      padding: 20px;
    }
  }
  @include md {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
