@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

h3.title {
  text-transform: uppercase;
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  color: $primary-color;
  margin-bottom: 100px;
  padding: 0px 20px;
  @include xl {
    font-size: 36px;
    margin-bottom: 40px;
  }
  @include md {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
