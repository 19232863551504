@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #110f0f;
  padding: 28px 20px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .appLogo {
      max-width: 552px;
      margin-right: 100px;
      cursor: pointer;
      @include xl {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > ul {
        display: flex;
        align-items: center;
        li {
          cursor: pointer;
          font-family: Inter;
          font-size: 24px;
          font-style: italic;
          font-weight: 300;
          color: $primary-color;
          margin-right: 60px;
          &:hover {
            transform: scale(1.1);
          }
          @include xxl {
            margin-right: 40px;
          }
        }
        @include md {
          display: none;
        }
      }
      > .social {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        align-items: center;
        > div {
          cursor: pointer;
          border: 1px solid $white;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .mobileMenu {
        display: none;
        .hamburgerIcon {
          cursor: pointer;
          border: 1px solid $white;
          width: 30px;
          height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 3px;
          span {
            width: 100%;
            height: 1px;
            background-color: $white;
            &:nth-of-type(2) {
              margin: 5px 0px;
            }
          }
        }
        .sidebarMenu {
          position: fixed;
          top: 0;
          left: -100%;
          z-index: 9999;
          width: 100%;
          height: 100vh;
          background-color: #110f0f;
          padding: 10px;
          transition: 0.3s;
          &.opened {
            left: 0;
          }
          .closeSidebarMenu {
            display: flex;
            justify-content: flex-end;
            span {
              border: 1px solid $white;
              color: $white;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 30px;
              cursor: pointer;
            }
          }
          ul {
            margin-top: 30px;
            margin-bottom: 30px;
            li {
              text-align: center;
              cursor: pointer;
              font-family: Inter;
              font-size: 24px;
              font-style: italic;
              font-weight: 300;
              color: $primary-color;
              padding: 10px;
              border-bottom: 1px solid #ff000050;
            }
          }
        }
        .social {
          display: flex;
          justify-content: center;
          > div {
            cursor: pointer;
            border: 1px solid $white;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:nth-of-type(1) {
              margin-right: 10px;
            }
          }
        }
        @include md {
          display: block;
        }
      }
      @include xl {
        width: 100%;
      }
    }
    @include xl {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
